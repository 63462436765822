
// IonInput
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";
import Empty from "../../../../components/Empty.vue";
import { useStore } from "vuex";
import { Analytics } from "../../../../common/analytics";
/**
 * services
 */
import services from "../../../../services";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonSpinner,
    Empty,
    IonRefresher,
    IonRefresherContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    GoogleMap,
    Marker,
  },
  props: {
    // postId
    id: {
      type: [Number],
      default: () => null,
    },
  },

  setup(props) {
    const store = useStore();
    const screenName = `PAGE-STAMP_${props.id}_MAP`;
    const analytics = new Analytics();
    const state = reactive({
      stamp: {
        loading: true,
        data: {
          id: 0,
          name: store.getters["getPage"].title,
          content: {},
          address: {},
        },
      },
      markerOptions: {
        position: {
          lat: 0,
          lng: 0,
        },
        icon: {
          url: "/assets/icon/map_place.svg",
          scaledSize: { width: 30, height: 30 },
        },
        // label: 'L',
        // title: 'LADY LIBERTY'
      },
      contentLoading: true,
    });

    const fetch = async (event: any = null, loadMore = false) => {
      if (!loadMore) {
        await services.stampFindone(props.id).then(
          (response) => {
            const { data } = response;

            if (event) {
              if (event.type == "ion-refresh") {
                state.stamp.data.id = 0;
              }
            }

            state.markerOptions.position.lat = data.lat;
            state.markerOptions.position.lng = data.lng;

            console.log(state.markerOptions);

            state.stamp.data = data;
            state.stamp.loading = false;
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.stamp.data.id = 0;
              }
            }
            state.stamp.loading = false;
          }
        );
      }

      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.stamp.loading = false;

      fetch(event);
    };

    // onMounted
    onMounted(() => {
      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    return { state, onRefresh };
  },
});
